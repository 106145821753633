import * as React from "react";

import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Send } from "../utils/WebViewClient";
import { normalizeColor } from "grommet/utils";
import Theme from "../utils/Theme";
import { useDarkMode } from "./DarkModeSwitch";

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.7rem 1rem;
  &:hover {
    background-color: ${(props) => props.color};
  }
`;

let ScreenControls = () => {
  const [isDarkMode] = useDarkMode();

  return (
    <div style={{ float: "right", display: "flex" }}>
      <IconBox color={normalizeColor("background", Theme, isDarkMode)} onClick={() => Send({ type: "minimize" })}>
        <FontAwesomeIcon icon={["fal", "window-minimize"]}></FontAwesomeIcon>
      </IconBox>
      <IconBox color={normalizeColor("background", Theme, isDarkMode)} onClick={() => Send({ type: "maximize" })}>
        <FontAwesomeIcon icon={["fal", "square"]}></FontAwesomeIcon>
      </IconBox>
      <IconBox color={normalizeColor("status-critical", Theme, isDarkMode)} onClick={() => Send({ type: "close" })}>
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={["fal", "xmark"]}></FontAwesomeIcon>
      </IconBox>
    </div>
  );
};

export default ScreenControls;
