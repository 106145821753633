import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

import styled from "styled-components";

const FilledPara = styled.p`
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

import { Anchor, Box, Button, FormField, TextInput } from "grommet";
import SkeletonWrapper from "./components/SkeletonWrapper";

import ScadsysAppButton from "./components/ScadsysAppButton";
import {
  ScadsysApps,
  Send,
  useAvailableScadsysApps,
  useBiometrics,
  useDataBindings,
  useEnrollFingerprint,
  useFingerprintReader,
  useInstalledScadsysApps,
  useUser,
} from "./utils/WebViewClient";
import SchoolSelector from "./components/SchoolSelector";
import { School } from "./components/SchoolItem";
import { Link } from "react-router-dom";
import ScrollContainer from "./components/ScrollContainer";
import StyledModal from "./components/StyledModel";
import { normalizeColor } from "grommet/utils";
import { useDarkMode } from "./components/DarkModeSwitch";
import Theme from "./utils/Theme";
import LinkAnchor from "./components/LinkAnchor";

const UserDiv = styled.div`
  background-color: ${({ darkMode }) =>
    normalizeColor("background-front", Theme, darkMode)};
  border-radius: 15px;
  transition: background-color 0.3s ease;
  padding: 1rem;
  margin: 1rem;
  font-size: 0.8rem;

  &:hover {
    background-color: ${({ darkMode }) =>
      normalizeColor("background", Theme, darkMode)};
    cursor: pointer;
  }
`;

let Enrollment = () => {
  const [init, setInit] = React.useState(false);

  const [fingerprintStatusModal, setFingerprintStatusModal] =
    React.useState(false);

  const [biometricService, setBiometricService] = React.useState(false);

  const [scaling, setScaling] = React.useState(1);

  React.useEffect(() => {
    Send({ type: "init" }).then((response: any) => {
      if (response.success) {
        setInit(true);
        setScaling(response.scaling);
      }
    });
  }, []);

  React.useEffect(() => {
    if (init) {
      Send({
        type: "biometricCheck",
        school: JSON.parse(localStorage.getItem("selectedSchool")),
      }).then((response: any) => {
        setLoading(false);
        setBiometricService(response.success);
        if (response.success) {
        }
      });
    }
  }, [init]);

  let biometrics = useBiometrics();
  let fingerprintReader = useFingerprintReader();

  const [userQuery, setUserQuery] = React.useState("");
  const [users, setUsers] = React.useState([]);

  let searchUsers = () => {
    setLoading(true);
    Send({
      type: "searchUsers",
      serviceUrl: biometrics.serviceUrl,
      userQuery,
    }).then((response: any) => {
      setUsers(response.users);
      setLoading(false);
    });
  };

  const [selectedUser, setSelectedUser] = React.useState({} as any);

  const [loading, setLoading] = React.useState(true);

  let startEnrollment = () => {
    setLoading(true);
    Send({
      type: "enrollFingerprint",
      serviceUrl: biometrics.serviceUrl,
      userQuery,
    }).then((response: any) => {});
  };

  const enrollmentEvent = useEnrollFingerprint();

  React.useEffect(() => {
    setLoading(false);
  }, [enrollmentEvent]);

  const cancel = () => {
    setSelectedUser({});
  };

  const save = () => {
    Send({
      type: "saveFingerprint",
      serviceUrl: biometrics.serviceUrl,
      userId: selectedUser.userId,
    }).then((response: any) => {
      setSelectedUser({});
    });
  };

  const [isDarkMode] = useDarkMode();

  const green = normalizeColor("status-ok", Theme, isDarkMode);
  const red = normalizeColor("status-critical", Theme, isDarkMode);

  return (
    <div style={{ padding: "1rem", zoom: 1 / scaling }}>
      <Box fill pad={{ left: "large", right: "large" }}>
        <LinkAnchor
          path="/"
          label={
            <>
              <FontAwesomeIcon
                icon={["fad", "chevron-left"]}
                style={{ marginRight: "5px" }}
              ></FontAwesomeIcon>{" "}
              Back
            </>
          }
        ></LinkAnchor>
        <div style={{ display: "flex" }}>
          <div>
            <span>Organization </span>
            {biometricService ? (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "shield-check"]}
                style={{ marginRight: "5px", color: green }}
              ></FontAwesomeIcon>
            ) : (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "shield-xmark"]}
                style={{ marginRight: "5px", color: red }}
              ></FontAwesomeIcon>
            )}
          </div>
          <div>
            <Anchor
              href=""
              onClick={(e) => {
                e.preventDefault();
                biometrics.success &&
                  Send({ type: "openLink", url: biometrics.serviceUrl });
              }}
              style={{
                color:
                  biometrics.success &&
                  normalizeColor("neutral-3", Theme, isDarkMode),
                textDecoration: biometrics.success && "underline",
              }}
            >
              Biometric Service{" "}
            </Anchor>
            {biometrics.success ? (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "cloud-check"]}
                style={{ marginRight: "5px", color: green }}
              ></FontAwesomeIcon>
            ) : (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "cloud-xmark"]}
                style={{ marginRight: "5px", color: red }}
              ></FontAwesomeIcon>
            )}
          </div>
          <div>
            <Anchor
              href=""
              onClick={(e) => {
                e.preventDefault();
                setFingerprintStatusModal(true);
              }}
              style={{
                color: "",
                textDecoration: "underline",
              }}
            >
              Fingerprint Reader{" "}
            </Anchor>
            {fingerprintReader.success ? (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "fingerprint"]}
                style={{ marginRight: "5px", color: green }}
              ></FontAwesomeIcon>
            ) : (
              //@ts-ignore
              <FontAwesomeIcon
                icon={["fad", "circle-exclamation"]}
                style={{ marginRight: "5px", color: red }}
              ></FontAwesomeIcon>
            )}
          </div>
        </div>
        <FormField label="Search">
          <TextInput
            type="text"
            placeholder="Search a user"
            value={userQuery}
            onChange={(e) => setUserQuery(e.target.value)}
            disabled={!biometrics.success}
          />
        </FormField>
        <Button
          primary
          onClick={() => {
            searchUsers();
          }}
          disabled={!biometrics.success}
          label="Search"
        ></Button>
        <SkeletonWrapper loading={loading}>
          <div style={{ display: "flex" }}>
            <div>
              <ScrollContainer style={{ maxHeight: "calc(100vh - 9rem)" }}>
                {users.map((u, i) => (
                  <UserDiv
                    key={i}
                    onClick={() => {
                      setSelectedUser(u);
                    }}
                    darkMode={isDarkMode}
                  >
                    {u.name}{" "}
                    <FontAwesomeIcon
                      icon={["fad", "chevron-right"]}
                      style={{ marginRight: "5px" }}
                    ></FontAwesomeIcon>
                  </UserDiv>
                ))}
              </ScrollContainer>
            </div>
            <div style={{ padding: "1rem" }}>
              {selectedUser.userId && fingerprintReader.success && (
                <div>
                  <div>{selectedUser.name}</div>
                  <Button
                    onClick={() => {
                      startEnrollment();
                    }}
                    label="Start Scanning"
                  ></Button>
                  <div>
                    <img
                      src={"data:image/jpeg;base64," + enrollmentEvent.image}
                      width="75px"
                      alt=""
                    />

                    {enrollmentEvent.status == 1 && (
                      <div>
                        Scanning {enrollmentEvent.scanIndex + 1} of 3
                        fingerprints
                      </div>
                    )}
                    {enrollmentEvent.status == 0 && (
                      <div>
                        Scanning complete, save fingerprint before continuing
                      </div>
                    )}
                    {enrollmentEvent.status == 2 && (
                      <div>{enrollmentEvent.message}</div>
                    )}
                  </div>
                  {(enrollmentEvent.status == 1 ||
                    enrollmentEvent.status == 0) && (
                    <Button
                      onClick={() => {
                        cancel();
                      }}
                      label="Cancel"
                    ></Button>
                  )}
                  {enrollmentEvent.status == 0 && (
                    <Button
                      primary
                      onClick={() => {
                        save();
                      }}
                      label="Save"
                    ></Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </SkeletonWrapper>
      </Box>

      <StyledModal
        isOpen={fingerprintStatusModal}
        setIsOpen={setFingerprintStatusModal}
      >
        <Box width={"medium"}>
          <span>Fingerprint Reader </span>
          {fingerprintReader.success ? (
            //@ts-ignore
            <FontAwesomeIcon
              icon={["fad", "fingerprint"]}
              style={{ marginRight: "5px", color: green }}
            ></FontAwesomeIcon>
          ) : (
            //@ts-ignore
            <FontAwesomeIcon
              icon={["fad", "circle-exclamation"]}
              style={{ marginRight: "5px", color: red }}
            ></FontAwesomeIcon>
          )}
          <div>{fingerprintReader.message || "No Message"}</div>
          <Button
            onClick={() => {
              setFingerprintStatusModal(false);
            }}
            label="Close"
          ></Button>
        </Box>
      </StyledModal>
    </div>
  );
};

export default Enrollment;
