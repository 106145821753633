export const putSchoolBadgeIntoCache = (schoolId: string, badge: string) => {
    localStorage.setItem(`school-badge-${schoolId}`, badge);
};

export const getSchoolBadgeFromCache = (schoolId: string) => {
    return localStorage.getItem(`school-badge-${schoolId}`);
};

export const attemptClearSchoolBadgeCache = () => {
    let lastCacheClear = localStorage.getItem(`school-badge-cache-clear`);

    if (lastCacheClear) {
        let lastCacheClearDate = new Date(lastCacheClear);
        let now = new Date();
        let diff = Math.abs(now.getTime() - lastCacheClearDate.getTime());
        let diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        if (diffDays < 3) {
            return;
        }
    }

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("school-badge-")) {
            localStorage.removeItem(key);
        }
    }
    localStorage.setItem(`school-badge-cache-clear`, new Date().toISOString());
};
