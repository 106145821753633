import * as React from "react";
import { Box, Layer } from "grommet";
class StyledModalProps {
  isOpen: boolean;
  setIsOpen?: Function;
  children: any;
}

const StyledModal = ({ isOpen, setIsOpen, children }: StyledModalProps) => {
  const close = () => setIsOpen && setIsOpen(false);

  if (isOpen)
    return (
      <Layer responsive={false} onEsc={close} onClickOutside={close}>
        <Box pad="medium">{children}</Box>
      </Layer>
    );

  return <></>;
};

export default StyledModal;
