import { base } from "grommet";
import { deepMerge } from "grommet/utils";
import { ThemeType } from "grommet/themes";

const presenceTheme: ThemeType = {
  global: {
    font: {
      size: "16px",
      weight: "400",
    },
    colors: {
      /* BEGIN: Color Palette Definition */
      brand: "#17a2b8",
      "light-1": "#f7f7f7",
      "light-2": "#e1e1e1",
      "light-3": "#b1b1b1",
      "dark-1": "#223047",
      "dark-2": "#151e29",
      "dark-3": "#000000",
      /* END: Color Palette Definition */
      /* BEGIN: Mapping Colors to Grommet Namespaces */
      background: {
        dark: "dark-2",
        light: "light-2",
      },
      "background-back": {
        dark: "dark-3",
        light: "light-3",
      },
      "background-front": {
        dark: "dark-1",
        light: "light-1",
      },
      muted: {
        dark: "light-3",
        light: "dark-1",
      },
      control: {
        dark: "brand",
      },
    },
    focus: {
      border: {
        color: "#17a2b8",
      },
    },
    /* END: Mapping Colors to Grommet Namespaces */
  },
  /* BEGIN: Mapping Colors to Components */
  anchor: {
    color: "text",
    hover: {
      textDecoration: "none",
    },
  },
  button: {
    border: {
      radius: "4px",
    },
  },
  layer: {
    background: {
      dark: "background",
      light: "background",
    },
  },
  menu: {
    background: {
      dark: "background",
      light: "background",
    },
  },
  tip: {
    content: {
      background: {
        dark: "dark-2",
        light: "light-2",
      },
    },
  },
  checkBox: {
    size: "16px",
    icon: {
      size: "12px",
    },
  },
  /* END: Mapping Colors to Components */
};

const Theme = deepMerge(base, presenceTheme);

export default Theme;
