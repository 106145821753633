import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as React from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";
import Home from "./Home";
import DarkModeSwitch, { useDarkMode } from "./components/DarkModeSwitch";
import ScreenControls from "./components/ScreenControls";
import { Send, useDataBindings, useUser } from "./utils/WebViewClient";

import LoginButton from "./components/LoginButton";
import Enrollment from "./Enrollment";
import { Box, Button, Grommet, Layer, Stack } from "grommet";
import Theme from "./utils/Theme";
import useEventListener from "@use-it/event-listener";
import Installer from "./Installer";

const BottomRight = styled.div`
    position: absolute;
    font-size: 0.8rem;
    bottom: 0;
    right: 0;
`;

const ControlBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingText = styled.div`
    font-size: 1.5rem;
    font-family: "OpenSansLight";
    padding: 0 0.5rem;
`;

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    const [showDarkModeSwitch, setShowDarkModeSwitch] = React.useState(false);
    const [isDarkMode, toggleDarkMode] = useDarkMode();

    setTimeout(() => {
        setShowDarkModeSwitch(true);
    }, 1000);

    useEventListener(
        "keydown",
        (e: KeyboardEvent) => {
            e.key === "F10" && toggleDarkMode();
        },
        window
    );

    return (
        <Grommet full theme={Theme} themeMode={isDarkMode ? "dark" : "light"} style={{ overflow: "hidden" }}>
            <MsalProvider instance={pca}>
                <Box justify="around" background={"background-front"}>
                    <ControlBar>
                        <HeadingText>Scadsys</HeadingText>
                        <div style={{ display: "flex" }}>
                            {showDarkModeSwitch && <DarkModeSwitch></DarkModeSwitch>}
                            <LoginButton></LoginButton>
                            <ScreenControls></ScreenControls>
                        </div>
                    </ControlBar>
                </Box>
                <Box height={{ min: "90%" }} style={{ overflow: "auto" }}>
                    <Stack fill anchor="bottom-left">
                        <Routes>
                            <Route path="/*" element={<Home></Home>}></Route>
                            <Route path="/enroll" element={<Enrollment></Enrollment>}></Route>
                            <Route path="/installer" element={<Installer></Installer>}></Route>
                        </Routes>
                        <Box
                            pad="small"
                            background={"background-front"}
                            hoverIndicator={{ background: "background" }}
                            onClick={() => {
                                window.location.reload();
                            }}
                            basis="auto"
                            round="5px"
                            margin="small"
                        >
                            <FontAwesomeIcon icon={["fal", "refresh"]}></FontAwesomeIcon>
                        </Box>
                    </Stack>
                </Box>
            </MsalProvider>
        </Grommet>
    );
}

export default App;
