import * as React from "react";
import { singletonHook } from "react-singleton-hook";
import styled from "styled-components";

const FilledPara = styled.p`
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

import { Box, Button, Grid, Layer, Meter, Text } from "grommet";
import SkeletonWrapper from "./components/SkeletonWrapper";
import ScadsysAppButton from "./components/ScadsysAppButton";
import { ScadsysApps, Send, useAppInstallProgress, useAvailableScadsysApps, useDataBindings, useInstalledScadsysApps, useUser } from "./utils/WebViewClient";
import SchoolSelector from "./components/SchoolSelector";
import { School } from "./components/SchoolItem";
import StyledModal from "./components/StyledModel";
import useEventListener from "@use-it/event-listener";
import { useAllowSearch } from "./components/AllowSearch";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "./components/ScrollContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const errorCodes = {
    "SCA-LEG-0001": "You are not authorized to access this application, or this device is not recognized.",
    "SCA-IDE-0005": "System is not setup for single sign-on.",
    "SCA-IDE-0006": "Your user does not have a staff profile linked.",
    "SCA-IDE-0007": "Insufficient privileges.",
};

let Home = () => {
    const [init, setInit] = React.useState(false);

    const [confirmDownloadModalIsOpen, setConfirmDownloadModalIsOpen] = React.useState(false);
    const [appStartingModalIsOpen, setAppStartingModalIsOpen] = React.useState(false);

    const [appToRun, setAppToRun] = React.useState({} as any);
    const [school, setSchool] = React.useState({} as School);
    const [scaling, setScaling] = React.useState(1);

    let installedApps = useInstalledScadsysApps();
    let availableApps = useAvailableScadsysApps();

    React.useEffect(() => {
        Send({ type: "init", height: document.body.clientHeight }).then((response: any) => {
            if (response.success) {
                setInit(true);
                setScaling(response.scaling);
            }
        });
    }, []);

    React.useEffect(() => {
        if (init) {
            Send({ type: "loadApps" });
            Send({ type: "loadSchools" });
            Send({ type: "getUser" });
        }
    }, [init]);

    let runApp = (app) => () => {
        setYearSelectOpen(false);
        setAppStartingModalIsOpen(true);

        Send({ type: "runApp", app, school }).then((response: any) => {
            setAppStartingModalIsOpen(false);
            if (response.confirm) {
                setAppToRun(app);
                setConfirmDownloadModalIsOpen(true);
            } else {
                showError(response);
            }
        });
    };

    let runAppInYear = (app, year) => () => {
        setYearSelectOpen(false);
        setAppStartingModalIsOpen(true);

        let historySchool = { ...school, webRef: school.webRef + year };

        Send({ type: "runApp", app, school: historySchool }).then((response: any) => {
            setAppStartingModalIsOpen(false);
            if (response.confirm) {
                setAppToRun(app);
                setConfirmDownloadModalIsOpen(true);
            } else {
                showError(response);
            }
        });
    };

    let runAppConfirm = () => {
        setConfirmDownloadModalIsOpen(false);
        setAppStartingModalIsOpen(true);

        Send({ type: "runAppConfirm", app: appToRun, school }).then((response: any) => {
            setAppToRun({});
            setAppStartingModalIsOpen(false);
            showError(response);
        });
    };

    let installApp = (app) => () => {
        setAppStartingModalIsOpen(true);

        Send({ type: "installApp", app, school }).then((response: any) => {
            showError(response);
            setAppStartingModalIsOpen(false);
        });
    };

    const showError = (errorResponse) => {
        if (!errorResponse.ssoSuccess && errorResponse.ssoMessage) {
            if (errorResponse.ssoMessage === "SCA-IDE-0005") {
                setShowErrorNotification(true);
                setTimeout(() => {
                    setShowErrorNotification(false);
                }, 5000);
            } else {
                setShowErrorModal(true);
            }
            setError(errorResponse.ssoMessage);
        }
    };

    let [biometricService, setBiometricService] = React.useState(false);
    let [biometricOrganization, setBiometricOrganization] = React.useState("");

    let handleSchoolSelect = (school) => {
        setSchool(school);

        Send({ type: "biometricCheck", school }).then((response: any) => {
            setBiometricService(response.success);
            setBiometricOrganization(response.organizationID);
        });
    };

    let dataBindings = useDataBindings();

    let showBiometricButton = () => {
        return dataBindings.consultantLogin === true || dataBindings.permissions.filter((p) => p == biometricOrganization + ":presence:createBiometricData").length > 0;
    };

    const [yearSelectOpen, setYearSelectOpen] = React.useState(false);
    const [app, setApp] = React.useState({} as any);

    const contextMenu = (app) => (e) => {
        e.preventDefault();
        setYearSelectOpen(true);
        setApp(app);
    };

    const [search, setSearch] = React.useState("");
    const allowSearch = useAllowSearch();

    React.useEffect(() => {
        if (!allowSearch) {
            setSearch("");
        }
    }, [allowSearch]);

    useEventListener(
        "keydown",
        (e: KeyboardEvent) => {
            if (!e.getModifierState("Control") && allowSearch) {
                if (/^[a-zA-Z]$/.test(e.key)) {
                    setSearch(search + e.key.toLocaleLowerCase());
                }

                if (e.key == "Escape") {
                    setSearch("");
                }

                if (e.key == "Backspace") {
                    setSearch(search.substring(0, search.length - 1));
                }
            }
        },
        window
    );

    const navigate = useNavigate();

    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const [error, setError] = React.useState("");

    const appInstallProgress = useAppInstallProgress();

    return (
        <div style={{ paddingTop: "1rem", zoom: 1 / scaling }}>
            <Box fill pad={{ left: "large", right: "large" }}>
                <Grid columns={["auto", "flex"]} gap={"large"}>
                    <Box align="center">
                        <SchoolSelector onChange={handleSchoolSelect} search={search}></SchoolSelector>
                    </Box>

                    <Box align="center">
                        {!school.webUrl && <FilledPara>Select a school to continue.</FilledPara>}
                        {school.webUrl && (
                            <>
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr 1fr",
                                        gridGap: "1rem",
                                        width: "100%",
                                    }}
                                >
                                    {installedApps.map((a, i) => (
                                        <div key={"a" + i} onClick={runApp(a)} onContextMenu={contextMenu(a)}>
                                            <ScadsysAppButton {...ScadsysApps[a.name]} {...a} key={"a" + i}></ScadsysAppButton>
                                        </div>
                                    ))}
                                    {availableApps.length > 0 &&
                                        availableApps.map((a, i) => (
                                            <div key={"a" + i} onClick={installApp(a)}>
                                                <ScadsysAppButton {...ScadsysApps[a.name]} {...a} key={"a" + i}></ScadsysAppButton>
                                            </div>
                                        ))}
                                    {showBiometricButton() && (
                                        <div key={"ab"} onClick={() => navigate("/enroll")}>
                                            <ScadsysAppButton {...ScadsysApps["Scadsys Biometrics"]} installedVersion="Enrollment" latestVersion="Enrollment" appStatus={biometricService}></ScadsysAppButton>
                                        </div>
                                    )}
                                </div>
                                {installedApps.length == 0 && availableApps.length == 0 && !showBiometricButton() && <FilledPara>You do not have access to any apps at this school.</FilledPara>}
                            </>
                        )}
                    </Box>
                </Grid>
            </Box>
            <StyledModal isOpen={confirmDownloadModalIsOpen}>
                <p>Scadsys will now update, if you have any open Scadsys windows, close them before continuing or your work may be lost</p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "1rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            setConfirmDownloadModalIsOpen(false);
                            setAppStartingModalIsOpen(false);
                            setAppToRun("");
                        }}
                        label="Cancel"
                    ></Button>
                    <Button primary reverse onClick={runAppConfirm} label="Update"></Button>
                </div>
            </StyledModal>

            <StyledModal isOpen={appStartingModalIsOpen}>
                <p>Please wait for app to start</p>
                <br />
                {appInstallProgress.progress > 0 && <Meter value={appInstallProgress.progress} max={100} width="200px" color="brand" />}
            </StyledModal>

            <StyledModal isOpen={showErrorModal} setIsOpen={setShowErrorModal}>
                <p>
                    <pre>{errorCodes[error] || error}</pre>
                </p>
                <Box direction="row" justify="center">
                    <Button label="Ok" onClick={() => setShowErrorModal(false)}></Button>
                </Box>
            </StyledModal>

            <StyledModal isOpen={yearSelectOpen} setIsOpen={setYearSelectOpen}>
                <Box gap="small" align="center">
                    <p>Launching {app.name}</p>
                    <p>Select Year</p>
                    <Button fill label={"Current"} onClick={runApp(app)}></Button>
                    <Button fill label={new Date().getFullYear() - 1} onClick={runAppInYear(app, new Date().getFullYear() - 1)}></Button>
                </Box>
            </StyledModal>

            {search && (
                <Layer position="bottom-left" modal={false} margin={{ vertical: "medium", horizontal: "small" }} responsive={false} plain>
                    <Box align="center" direction="row" gap="small" justify="between" round="medium" pad={{ vertical: "xsmall", horizontal: "small" }} background="brand" onClick={() => setSearch("")}>
                        <Box align="center" direction="row" gap="xsmall">
                            {search}
                        </Box>
                    </Box>
                </Layer>
            )}

            {showErrorNotification && (
                <Layer position="bottom-right" modal={false} margin={{ vertical: "medium", horizontal: "small" }} responsive={false} plain>
                    <Box align="center" direction="row" gap="small" justify="between" round="small" pad={{ vertical: "xsmall", horizontal: "medium" }} background="background-back" onClick={() => setShowErrorNotification(false)}>
                        <Box align="center" direction="row" gap="xsmall">
                            <Text>Warning</Text>
                            <Text>{errorCodes[error] || error}</Text>
                            <Button icon={<FontAwesomeIcon icon={["fal", "close"]}></FontAwesomeIcon>}></Button>
                        </Box>
                    </Box>
                </Layer>
            )}
        </div>
    );
};

export default Home;
