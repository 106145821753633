import * as React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Send, useDataBindings, useUser } from "../utils/WebViewClient";
import { TextInput, Button, Box, Clock, Text, CheckBox } from "grommet";
import SkeletonWrapper from "./SkeletonWrapper";
import StyledModal from "./StyledModel";
import { useDarkMode } from "./DarkModeSwitch";
import { normalizeColor } from "grommet/utils";
import Theme from "../utils/Theme";
import { setAllowSearch } from "./AllowSearch";
const LoginButtonDiv = styled.div`
    font-family: "OpenSansLight";
    padding: 0.4rem 1rem;

    &:hover {
        background-color: ${({ darkMode }) => normalizeColor("background", Theme, darkMode)};
        cursor: pointer;
    }
`;

class Preferences {
    useLegacyInstallExperience: boolean;
    checkAccessForApps: boolean;
}

let LoginButton = () => {
    const [isLoading, setIsLoading] = React.useState(true);

    const [showLoginModal, setShowLoginModal] = React.useState(false);
    const [showLogoutModal, setShowLogoutModal] = React.useState(false);
    const [showSignInWaitingModal, setShowSignInWaitingModal] = React.useState(false);
    const [showSignInFailedModal, setShowSignInFailedModal] = React.useState(false);

    const [email, setEmail] = React.useState("");
    const [preferences, setPreferences] = React.useState({} as Preferences);

    const dataBindings = useDataBindings();
    const user = useUser();

    const [isDarkMode] = useDarkMode();

    React.useEffect(() => {
        if (dataBindings.userParam) {
            setEmail(dataBindings.userParam);
        }
        if (dataBindings.preferences) {
            setPreferences(dataBindings.preferences);
        }
    }, [dataBindings]);

    React.useEffect(() => {
        if (!isLoading) {
            Send({ type: "updatePreferences", preferences }).then((response: any) => {});
        }
    }, [preferences]);

    React.useEffect(() => {
        setAllowSearch(!showLoginModal && !showLogoutModal && !showSignInWaitingModal && !showSignInFailedModal);
    }, [showLoginModal, showLogoutModal, showSignInWaitingModal, showSignInFailedModal]);

    let signIn = () => {
        setShowLoginModal(false);
        setShowSignInWaitingModal(true);
        Send({ type: "signIn", email: email }).then((response: any) => {
            if (response.success) {
            } else {
                if (showSignInWaitingModal) {
                    setShowSignInFailedModal(true);
                }
            }
            setShowSignInWaitingModal(false);
        });
    };

    let startSignIn = () => {
        if (!dataBindings.loggedIn) {
            setShowLoginModal(true);
        } else {
            setShowLogoutModal(true);
        }
    };

    let cancelSignIn = () => {
        Send({ type: "cancelSignIn" }).then((response: any) => {
            setShowSignInWaitingModal(false);
        });
    };

    let signOut = () => {
        Send({ type: "signOut" }).then((response: any) => {
            setShowLogoutModal(false);
        });
    };

    React.useEffect(() => {
        Send({ type: "getUser" }).then((response) => {
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <SkeletonWrapper loading={isLoading}>
                <LoginButtonDiv onClick={startSignIn} darkMode={isDarkMode}>
                    {!dataBindings.loggedIn && <span>Login</span>}
                    {dataBindings.loggedIn && !dataBindings.consultantLogin && <FontAwesomeIcon icon={["fad", "user"]}></FontAwesomeIcon>}
                    {dataBindings.loggedIn && dataBindings.consultantLogin && <FontAwesomeIcon icon={["fad", "user-shield"]}></FontAwesomeIcon>}
                </LoginButtonDiv>
            </SkeletonWrapper>

            <StyledModal isOpen={showLoginModal} setIsOpen={setShowLoginModal}>
                <Box width="medium">
                    <CheckBox label="Use Legacy Install Experience" checked={preferences.useLegacyInstallExperience} onChange={(e) => setPreferences({ ...preferences, useLegacyInstallExperience: e.target.checked })}></CheckBox>
                    <CheckBox label="Check App Access" checked={preferences.checkAccessForApps} onChange={(e) => setPreferences({ ...preferences, checkAccessForApps: e.target.checked })}></CheckBox>
                    <p style={{ marginBottom: "0.5rem" }}>Enter your email address</p>
                    <TextInput style={{ width: "100%" }} placeholder="me@myschool.co.za" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem", gap: "1rem" }}>
                        <Button
                            onClick={() => {
                                setShowLoginModal(false);
                            }}
                            label="Cancel"
                        ></Button>
                        <Button primary reverse onClick={signIn} label="Continue"></Button>
                    </div>
                </Box>
            </StyledModal>

            <StyledModal isOpen={showLogoutModal} setIsOpen={setShowLogoutModal}>
                <Box width="medium">
                    <p style={{ marginBottom: "0.5rem" }}>{user.name}</p>
                    <CheckBox label="Use Legacy Install Experience" checked={preferences.useLegacyInstallExperience} onChange={(e) => setPreferences({ ...preferences, useLegacyInstallExperience: e.target.checked })}></CheckBox>
                    <CheckBox label="Check App Access" checked={preferences.checkAccessForApps} onChange={(e) => setPreferences({ ...preferences, checkAccessForApps: e.target.checked })}></CheckBox>
                    <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                        <Button
                            onClick={() => {
                                setShowLogoutModal(false);
                            }}
                            label="Close"
                        ></Button>
                        <Button primary reverse onClick={signOut} label="Logout"></Button>
                    </div>
                </Box>
            </StyledModal>

            <StyledModal isOpen={showSignInWaitingModal}>
                <p style={{ marginBottom: "0.5rem" }}>Signing in, please follow browser prompts, then return to the app.</p>
                <Box direction="row" gap="small" justify="evenly" align="end">
                    <Button label="Cancel" onClick={cancelSignIn}></Button>
                    <Clock time="PT0H0M60S" type="digital" run="backward" precision="seconds"></Clock>
                </Box>
            </StyledModal>

            <StyledModal isOpen={showSignInFailedModal} setIsOpen={setShowSignInFailedModal}>
                <Box width="medium">
                    <p style={{ marginBottom: "0.5rem" }}>Login failed, please try again.</p>
                    <Box gap="small" direction="row" justify="end">
                        <Button
                            onClick={() => {
                                setShowSignInFailedModal(false);
                            }}
                            label="Close"
                        ></Button>
                        <Button label="Retry" primary onClick={signIn}></Button>
                    </Box>
                </Box>
            </StyledModal>
        </>
    );
};

export default LoginButton;
