import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

import axios from "axios";

axios.interceptors.request.use(
    async function (config) {
        const account = msalInstance.getActiveAccount();
        if (account) {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });

            config.headers["Authorization"] = `IdentityBearer ${response.accessToken}`;
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export let TrimFields = (obj) => {
    Object.keys(obj).map((k) => obj[k] && (obj[k] = obj[k].trim()));
    return obj;
};

export default axios;