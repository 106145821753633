import { Configuration, PopupRequest, ProtocolMode, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        redirectUri: "/",
        postLogoutRedirectUri: "/",

        clientId: "a1394729-9a94-4926-bb7a-8c037d397a1f",
        // secrect:'jCAfxdnTmMzDp6yYWrrNVNP2icus7--GIixo4QoJAhMYcW_ZIZERoBzCCFGbjmonBySXUcdMzBZmDyDJyyYbsQ==',
        authority: IdentityServerUrl,
        knownAuthorities: [IdentityAuthority],
        protocolMode: ProtocolMode.OIDC,
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["openid", "profile", "default", "identity", "offline_access"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
