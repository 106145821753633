import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalizeColor } from "grommet/utils";
import Theme from "../utils/Theme";
import { useDarkMode } from "./DarkModeSwitch";

const ScadsysAppButtonContainer = styled.div`
  background-color: ${({ darkMode }) => normalizeColor("background-front", Theme, darkMode)};
  border-radius: 15px;
  transition: background-color 0.3s ease;
  padding: 1rem;

  &:hover {
    background-color: ${({ darkMode }) => normalizeColor("background", Theme, darkMode)};
    cursor: pointer;
  }
`;

const ScadsysAppButtonImageBlock = styled.div`
  position: relative;
  display: block;
  width: 80px;
  margin: auto;
`;

const ScadsysAppButtonAppImage = styled.img`
  max-width: 80px;
  max-height: 80px;
  display: block;
`;

const ScadsysAppButtonAppIcon = styled.img`
  position: absolute;
  max-width: 15px;
  max-height: 15px;
  background: ${({ darkMode }) => normalizeColor("background-back", Theme, darkMode)};
  border-radius: 5px;
  padding: 5px;
  display: block;
  top: 55px;
  left: 55px;
  width: 15px;
  height: 15px;
`;

const ScadsysAppButtonAppStatus = styled.div`
  position: absolute;
  width: 25px;
  max-height: 25px;
  background: ${({ darkMode }) => normalizeColor("background-back", Theme, darkMode)};
  border-radius: 5px;
  padding-left: 5px;
  top: 55px;
  left: 55px;
  text-align: center;
`;

const ScadsysAppButtonAppName = styled.p`
  text-align: center;
  margin: 0;
`;

const ScadsysAppButtonAppVersionDiv = styled.div`
  text-align: center;
  color: ${({ color, darkMode }) => normalizeColor(color, Theme, darkMode)};
  font-size: 0.7rem;
`;

const ScadsysAppButtonAppVersion = ({ installedVersion, latestVersion }) => {
  let [color, setColor] = React.useState("");
  let [version, setVersion] = React.useState(latestVersion);
  const [isDarkMode] = useDarkMode();

  React.useEffect(() => {
    if (installedVersion == latestVersion) {
      setColor("");
    } else if (installedVersion == "0.0.0.0") {
      setColor("status-ok");
      setVersion("Install");
    } else {
      setColor("red");
    }
  }, [installedVersion, latestVersion]);

  return (
    <ScadsysAppButtonAppVersionDiv color={color} darkMode={isDarkMode}>
      {version}
    </ScadsysAppButtonAppVersionDiv>
  );
};

const ScadsysAppButton = ({ installedVersion, latestVersion, appName, appIcon, appImage, appStatus }) => {
  const [isDarkMode] = useDarkMode();

  const green = normalizeColor("status-ok", Theme, isDarkMode);
  const red = normalizeColor("status-critical", Theme, isDarkMode);

  return (
    <ScadsysAppButtonContainer darkMode={isDarkMode}>
      <ScadsysAppButtonImageBlock>
        <ScadsysAppButtonAppImage src={appImage} alt="" />
        {appIcon && <ScadsysAppButtonAppIcon src={appIcon} alt="" darkMode={isDarkMode} />}
        {appStatus === true && (
          <ScadsysAppButtonAppStatus darkMode={isDarkMode}>
            <FontAwesomeIcon icon={["fad", "check"]} style={{ marginRight: "5px", color: green }}></FontAwesomeIcon>
          </ScadsysAppButtonAppStatus>
        )}
        {appStatus === false && (
          <ScadsysAppButtonAppStatus darkMode={isDarkMode}>
            {/* @ts-ignore */}
            <FontAwesomeIcon icon={["fad", "xmark"]} style={{ marginRight: "5px", color: red }}></FontAwesomeIcon>
          </ScadsysAppButtonAppStatus>
        )}
      </ScadsysAppButtonImageBlock>
      <ScadsysAppButtonAppName>{appName}</ScadsysAppButtonAppName>
      <ScadsysAppButtonAppVersion installedVersion={installedVersion} latestVersion={latestVersion}></ScadsysAppButtonAppVersion>
    </ScadsysAppButtonContainer>
  );
};

export default ScadsysAppButton;
