import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, animated } from "react-spring";
import * as React from "react";
import styled from "styled-components";
import { Button, CheckBox, FormField, TextInput, Text, Box } from "grommet";
import SkeletonWrapper from "./SkeletonWrapper";

import axios from "../utils/HttpClient";
import NewsListItem, { NewsItem } from "./NewsListItem";
import { Send, useDataBindings, useSchools, useUser } from "../utils/WebViewClient";
import SchoolItem, { School, SchoolDiv } from "./SchoolItem";
import ScrollContainer from "./ScrollContainer";
import StyledModal from "./StyledModel";
import { useDarkMode } from "./DarkModeSwitch";
import { getSchoolBadgeFromCache, putSchoolBadgeIntoCache } from "../utils/SchoolBadgeCache";
import { setAllowSearch } from "./AllowSearch";

const SchoolSelectorContainer = styled.div`
    min-height: 200px;
    max-width: 250px;
    margin: 0 auto 1rem auto;
    width: 250px;
`;

const SchoolBadge = styled.img`
    max-width: 140px;
    max-height: 140px;
    background: white;
    border-radius: 15px;
    padding: 10px;
    margin: auto;
    display: block;
`;

const CenterAligned = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
`;

const defaultBadge = "https://identity.scadsys.co.za/static/branding/images/logo";

const SchoolSelector = ({ onChange, search }) => {
    const [loadingSchoolBadge, setLoadingSchoolBadge] = React.useState(true);
    const [schoolBadge, setSchoolBadge] = React.useState(defaultBadge);
    const [school, setSchool] = React.useState({} as School);

    const [selectSchool, setSelectSchool] = React.useState(false);
    const [loadingNews, setLoadingNews] = React.useState(true);
    const [news, setNews] = React.useState([{}, {}, {}] as Array<NewsItem>);

    const [showMySchools, setShowMySchools] = React.useState(true);

    const dataBindings = useDataBindings();

    const [addSchoolModalOpen, setAddSchoolModalOpen] = React.useState(false);
    const [removeSchoolModalOpen, setRemoveSchoolModalOpen] = React.useState(false);

    const { transform, opacity } = useSpring({
        opacity: selectSchool ? 1 : 0,
        transform: `perspective(600px) rotateY(${selectSchool ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    });
    const [isDarkMode] = useDarkMode();

    // const [time, setTime] = React.useState(+new Date());

    React.useEffect(() => {
        axios.get("https://www.scadsys.co.za/wp-json/wp/v2/posts", { params: { fields: "title,date,link" } }).then((res) => {
            setNews(res.data.slice(0, 5));
            setLoadingNews(false);
        });
        var schoolJson = localStorage.getItem("selectedSchool");

        if (schoolJson) {
            handleSchoolChange(JSON.parse(schoolJson));
        }
    }, []);

    React.useEffect(() => {
        setAllowSearch(selectSchool);
    }, [selectSchool]);

    React.useEffect(() => {
        onChange && onChange(school);
        // setTime(+new Date());
    }, [school]);

    React.useEffect(() => {
        if (!dataBindings.loggedIn) {
            setSelectSchool(false);
            setShowMySchools(true);
        }
    }, [dataBindings]);

    const [mySchools, onlineSchools] = useSchools();

    let handleSchoolChange = (newSchool: School) => {
        setSchool(newSchool);
        localStorage.setItem("selectedSchool", JSON.stringify(newSchool));
        const cachedBadge = getSchoolBadgeFromCache(newSchool.webUrl + newSchool.webRef);

        if (cachedBadge) {
            setSchoolBadge(cachedBadge);
            setSelectSchool(false);
        } else {
            setSelectSchool(false);
            setSchoolBadge("");
            setLoadingSchoolBadge(true);
            Send({ type: "schoolBadge", url: newSchool.webUrl, ref: newSchool.webRef }).then((response: any) => {
                if (response.badge) {
                    setSchoolBadge(`data:image/jpeg;base64,${response.badge}`);
                    putSchoolBadgeIntoCache(newSchool.webUrl + newSchool.webRef, `data:image/jpeg;base64,${response.badge}`);
                } else {
                    setSchoolBadge(defaultBadge);
                }
            });
        }

        Send({ type: "selectSchool", school: newSchool }).then((response: any) => {});
    };

    React.useEffect(() => {
        if (mySchools.length == 1 && onlineSchools.length == 0) {
            if (mySchools[0].webRef != school.webRef || mySchools[0].webUrl != school.webUrl) {
                handleSchoolChange(mySchools[0]);
            }
        } else if (dataBindings.schoolParam) {
            if (dataBindings.consultantLogin) {
                let s = onlineSchools.filter((s) => s.code == dataBindings.schoolParam);

                if (s.length > 0) {
                    handleSchoolChange(s[0]);
                }
            } else {
                let s = mySchools.filter((s) => s.code == dataBindings.schoolParam);

                if (s.length > 0) {
                    handleSchoolChange(s[0]);
                }
            }
        }
    }, [mySchools, onlineSchools, dataBindings]);

    const startAddSchool = () => {
        setAllowSearch(false);
        setAddSchoolModalOpen(true);
    };

    const toggleAddSchoolModal = (value) => {
        setAddSchoolModalOpen(value);
        setAllowSearch(!value);
    };

    const [newConnectionName, setNewConnectionName] = React.useState("");
    const [newConnectionURL, setNewConnectionURL] = React.useState("");
    const [newConnectionReference, setNewConnectionReference] = React.useState("");

    const addSchool = () => {
        Send({ type: "addSchool", name: newConnectionName, url: newConnectionURL, reference: newConnectionReference }).then(() => {
            setNewConnectionName("");
            setNewConnectionURL("");
            setNewConnectionReference("");
            setAddSchoolModalOpen(false);
            setAllowSearch(true);
        });
    };

    const [schoolToRemove, setSchoolToRemove] = React.useState({} as School);

    const startRemoveSchool = (school: School) => {
        setRemoveSchoolModalOpen(true);
        setSchoolToRemove(school);
    };

    const removeSchool = () => {
        Send({ type: "removeSchool", name: schoolToRemove.name, url: schoolToRemove.webUrl, reference: schoolToRemove.webRef }).then(() => {
            setRemoveSchoolModalOpen(false);
            setSchoolToRemove({} as School);
        });
    };
    return (
        <div>
            {/* <link type="text/css" href={`https://identity.scadsys.co.za/static/branding/theme?time=${time}`} rel="stylesheet" /> */}
            <animated.div style={{ position: "absolute", opacity: opacity.to((o) => 1 - o), transform }}>
                <SchoolSelectorContainer>
                    <CenterAligned>
                        <Button size="small" onClick={() => setSelectSchool((s) => !s)} label={(school.webUrl ? "Switch " : "Select ") + "School"} icon={<FontAwesomeIcon icon={["fad", "retweet"]}></FontAwesomeIcon>} style={{ marginBottom: "1rem" }}></Button>
                        <SkeletonWrapper loading={loadingSchoolBadge}>
                            <SchoolBadge
                                src={schoolBadge}
                                alt=""
                                style={{ maxWidth: "160px", maxHeight: "160px", background: "white", borderRadius: "15px", padding: "10px", margin: "auto", display: "block" }}
                                onLoad={() => {
                                    setLoadingSchoolBadge(false);
                                }}
                            />
                        </SkeletonWrapper>
                        <br />
                        <h3 style={{ textAlign: "center", margin: "0" }}>{school.name}</h3>
                        {!selectSchool && (
                            <SkeletonWrapper loading={loadingNews}>
                                {/* <div style={{ margin: "1rem" }}>
                                    <h3 style={{ textAlign: "center", margin: "0" }}>News Feed</h3>
                                </div> */}

                                {/* <ScrollContainer style={{ maxHeight: "calc(100vh - 22rem)" }} darkMode={isDarkMode}>
                                    {news.map((n, i) => (
                                        <NewsListItem key={"n" + i} {...n}></NewsListItem>
                                    ))}
                                    <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => Send({ type: "openLink", url: "https://www.scadsys.co.za/" })}>
                                        <div>More News</div>
                                    </div>
                                </ScrollContainer> */}
                            </SkeletonWrapper>
                        )}
                    </CenterAligned>
                </SchoolSelectorContainer>
            </animated.div>
            <animated.div
                style={{
                    opacity,
                    transform,
                    rotateY: "180deg",
                    pointerEvents: selectSchool ? "unset" : "none",
                }}
            >
                <SchoolSelectorContainer>
                    <CenterAligned>
                        <h3 style={{ textAlign: "center", margin: "0" }}>
                            <FontAwesomeIcon onClick={() => setSelectSchool((s) => !s)} icon={["fad", "arrow-left"]}></FontAwesomeIcon> Choose School
                        </h3>
                        {dataBindings.consultantLogin && (
                            <div style={{ fontSize: "0.8rem", display: "flex", justifyContent: "space-evenly", alignItems: "center", marginBottom: "1rem" }}>
                                <CheckBox checked={showMySchools} onChange={(e) => setShowMySchools(e.target.checked)} reverse label="My Schools" />
                            </div>
                        )}
                    </CenterAligned>
                    <ScrollContainer style={{ maxHeight: "calc(100vh - 10rem)" }} darkMode={isDarkMode}>
                        {showMySchools && mySchools.filter((s) => s.name.toLowerCase().search(search) >= 0).map((s, i) => <SchoolItem key={"s" + i} school={s} onChange={handleSchoolChange} remove={() => startRemoveSchool(s)}></SchoolItem>)}
                        {dataBindings.consultantLogin && !showMySchools && onlineSchools.filter((s) => s.name.toLowerCase().search(search) >= 0).map((s, i) => <SchoolItem key={"s" + i} school={s} onChange={handleSchoolChange}></SchoolItem>)}
                        <SchoolDiv onClick={startAddSchool} darkMode={isDarkMode}>
                            <div>
                                {/* @ts-ignore */}
                                <FontAwesomeIcon icon={["fad", "plus"]} style={{ marginRight: "5px" }}></FontAwesomeIcon>
                                New
                            </div>
                        </SchoolDiv>
                    </ScrollContainer>
                </SchoolSelectorContainer>
            </animated.div>

            <StyledModal isOpen={addSchoolModalOpen} setIsOpen={toggleAddSchoolModal}>
                <Box width={"medium"}>
                    <FormField label="Name">
                        <TextInput size="xsmall" value={newConnectionName} onChange={(e) => setNewConnectionName(e.target.value)}></TextInput>
                    </FormField>
                    <FormField label="URL">
                        <TextInput size="xsmall" value={newConnectionURL} onChange={(e) => setNewConnectionURL(e.target.value)}></TextInput>
                    </FormField>
                    <FormField label="Reference">
                        <TextInput size="xsmall" value={newConnectionReference} onChange={(e) => setNewConnectionReference(e.target.value)}></TextInput>
                    </FormField>
                    <Button primary label="Add" onClick={addSchool}></Button>
                </Box>
            </StyledModal>

            <StyledModal isOpen={removeSchoolModalOpen} setIsOpen={setRemoveSchoolModalOpen}>
                <Text>Are you sure you want to remove this connection?</Text>
                <Box direction="row" gap="small" fill justify="evenly" pad={"small"}>
                    <Button label="Cancel" onClick={() => setRemoveSchoolModalOpen(false)}></Button>
                    <Button primary label="Remove" onClick={removeSchool}></Button>
                </Box>
            </StyledModal>
        </div>
    );
};

export default SchoolSelector;
