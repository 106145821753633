import * as React from "react";
import { values } from "lodash";
import { attemptClearSchoolBadgeCache } from "./SchoolBadgeCache";

function GetID() {
    return Math.floor(Math.random() * 10000000000).toString();
}

export const ScadsysApps = {
    "Scadsys Admin": { appIcon: "./images/ScadsysAdmin.ico", appName: "Admin", appImage: "./images/admin.png" },
    "Scadsys Assets": { appIcon: "./images/ScadsysAssets.ico", appName: "Assets", appImage: "./images/assets.png" },
    "Scadsys CRM": { appIcon: "./images/ScadsysCRM.ico", appName: "Classroom", appImage: "./images/crm.png" },
    "Scadsys Finance": { appIcon: "./images/ScadsysFinance.ico", appName: "Finance", appImage: "./images/finance.png" },
    "Scadsys Media": { appIcon: "./images/ScadsysMedia.ico", appName: "Media", appImage: "./images/media.png" },
    "Scadsys POS": { appIcon: "./images/ScadsysPOS.ico", appName: "Point of Sale", appImage: "./images/pos.png" },
    "Scadsys Security": { appIcon: "./images/ScadsysSecurity.ico", appName: "Security", appImage: "./images/security.png" },
    "Scadsys Alumni Donor": { appIcon: "./images/ScadsysAlumniDonor.ico", appName: "Donor", appImage: "./images/alumni.png" },
    "Scadsys Biometrics": { appIcon: "", appName: "Biometrics", appImage: "./images/biometrics.png" },
};

let listenerNames = [];

function ListenerFactory<DataType>(name, eventName) {
    if (listenerNames.indexOf(name) == -1) {
        listenerNames.push(name);
    }
    return function () {
        const [data, setData] = React.useState({} as DataType);

        const id = GetID();

        React.useEffect(() => {
            if (!listeners[name]) {
                listeners[name] = {};
            }
            listeners[name][id] = (event) => {
                let message = JSON.parse(event.data);
                if (message.type == eventName) {
                    setData(message);
                }
            };
            return () => {
                delete listeners[name][id];
            };
        });
        return data;
    };
}

export const useBiometrics = ListenerFactory<{ success: boolean; serviceUrl: string }>("biometrics", "biometricInit");
export const useFingerprintReader = ListenerFactory<{ success: boolean; message: string }>("fingerprint", "biometricReaderInit");
export const useEnrollFingerprint = ListenerFactory<{ status: number; message: string; scanIndex: number; image: string }>("enrollment", "enrollFingerprint");
export const useAppInstallProgress = ListenerFactory<{ progress: number }>("appInstallProgress", "appInstallProgress");

export function useInstalledScadsysApps() {
    const [scadsysApps, setScadsysApps] = React.useState([]);
    const id = GetID();

    React.useEffect(() => {
        listeners["installed"][id] = (event) => {
            let message = JSON.parse(event.data);
            if (message.type == "loadApps") {
                setScadsysApps(message.apps.installed.filter((a) => !!ScadsysApps[a.name]));
            }
        };
        return () => {
            delete listeners["installed"][id];
        };
    });
    return scadsysApps;
}

export function useAvailableScadsysApps() {
    const [availableApps, setAvailableApps] = React.useState([]);
    const id = GetID();

    React.useEffect(() => {
        listeners["available"][id] = (event) => {
            let message = JSON.parse(event.data);
            if (message.type == "loadApps") {
                setAvailableApps(message.apps.available.filter((a) => !!ScadsysApps[a.name]));
            }
        };
        return () => {
            delete listeners["available"][id];
        };
    });

    return availableApps;
}

export function useSchools() {
    const [mySchools, setMySchools] = React.useState([]);
    const [onlineSchools, setOnlineSchools] = React.useState([]);
    const id = GetID();
    React.useEffect(() => {
        listeners["schools"][id] = (event) => {
            let message = JSON.parse(event.data);
            if (message.type == "loadSchools") {
                setMySchools(message.schools.mySchools);
                setOnlineSchools(message.schools.onlineSchools);
            }
        };
        return () => {
            delete listeners["schools"][id];
        };
    });

    return [mySchools, onlineSchools];
}

function User(user) {
    const NAME_CLAIM = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
    const SUB_CLAIM = "sub";
    let nameClaim = [{ value: "" }];
    let subClaim = [{ value: "" }];

    if (user.claims) {
        nameClaim = user.claims.filter((c) => c.type == NAME_CLAIM) || [{}];
        subClaim = user.claims.filter((c) => c.type == SUB_CLAIM) || [{}];
    }

    return {
        name: nameClaim[0].value,
        sub: subClaim[0].value,
    };
}

export function useUser() {
    const [user, setUser] = React.useState({} as any);
    const id = GetID();

    React.useEffect(() => {
        listeners["user"][id] = (event) => {
            let message = JSON.parse(event.data);
            if (message.type == "getUser") {
                setUser(User(message.user || {}));
                attemptClearSchoolBadgeCache();
            }
        };
        return () => {
            delete listeners["user"][id];
        };
    });

    return user;
}

export function useDataBindings() {
    const [dataBindings, setDataBindings] = React.useState({
        loggedIn: false,
        consultantLogin: false,
        permissions: [],
        userParam: "",
        schoolParam: "",
    } as any);

    const id = GetID();

    React.useEffect(() => {
        listeners["databindings"][id] = (event) => {
            let message = JSON.parse(event.data);
            if (message.type == "getUser") {
                setDataBindings(message.customDataBindings);
            }
        };
        return () => {
            delete listeners["databindings"][id];
        };
    });

    return dataBindings;
}

let listeners = {
    installed: {},
    available: {},
    schools: {},
    user: {},
    biometrics: {},
    fingerprint: {},
    databindings: {},
};

//@ts-ignore
if (window.chrome && window.chrome.webview) {
    //@ts-ignore
    window.chrome.webview.addEventListener("message", Respond);
}

function Respond(event) {
    let message = JSON.parse(event.data);

    if (listeners[message.id]) {
        listeners[message.id](message);
        delete listeners[message.id];
    } else {
        listenerNames.forEach((name) => values(listeners[name]).forEach((l) => l & l(event)));

        values(listeners["installed"]).forEach((l) => l & l(event));
        values(listeners["available"]).forEach((l) => l & l(event));
        values(listeners["schools"]).forEach((l) => l & l(event));
        values(listeners["user"]).forEach((l) => l & l(event));
        values(listeners["biometrics"]).forEach((l) => l & l(event));
        values(listeners["fingerprint"]).forEach((l) => l & l(event));
        values(listeners["databindings"]).forEach((l) => l & l(event));
    }
}

export function Listen(id, fn) {
    listeners[id] = fn;
}

export function Send(data) {
    //@ts-ignore
    if (window.chrome && window.chrome.webview) {
        return new Promise((resolve, reject) => {
            var id = GetID();

            Listen(id, (data) => {
                resolve(data);
            });

            //@ts-ignore
            window.chrome.webview.postMessage({ id, ...data });
        });
    } else {
        return Promise.reject();
    }
}
