import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalizeColor } from "grommet/utils";

import * as React from "react";
import styled from "styled-components";
import Theme from "../utils/Theme";
import { useDarkMode } from "./DarkModeSwitch";

export class School {
    name: string;
    webUrl: string;
    webRef: string;
    code: string;
    managed: boolean;
}

export const SchoolDiv = styled.div`
    background-color: ${({ darkMode }) => normalizeColor("background-front", Theme, darkMode)};
    border-radius: 15px;
    transition: background-color 0.3s ease;
    padding: 0.75rem;
    margin: 0.75rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: ${({ darkMode }) => normalizeColor("background", Theme, darkMode)};
        cursor: pointer;
    }
`;

class SchoolItemProps {
    school: School;
    onChange: Function;
    remove?: Function;
}

const SchoolItem = ({ school, onChange, remove }: SchoolItemProps) => {
    let go = () => onChange && onChange(school);
    const [isDarkMode] = useDarkMode();
    return (
        <SchoolDiv {...school} darkMode={isDarkMode}>
            <div onClick={go} style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                {school.managed && (
                    <>
                        {/* @ts-ignore */}
                        <FontAwesomeIcon icon={["fad", "cloud-check"]} style={{ marginRight: "5px", color: normalizeColor("status-ok", Theme, isDarkMode) }}></FontAwesomeIcon>
                    </>
                )}
                {school?.name}
            </div>
            {remove && !school.managed && (
                <div onClick={() => remove()} style={{ marginLeft: "5px" }}>
                    <FontAwesomeIcon icon={["fad", "trash"]} style={{ color: normalizeColor("status-critical", Theme, isDarkMode) }}></FontAwesomeIcon>
                </div>
            )}
        </SchoolDiv>
    );
};

export default SchoolItem;
