import { normalizeColor } from "grommet/utils";
import * as React from "react";
import styled from "styled-components";
import Theme from "../utils/Theme";

const ScrollContainer = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: ${({ darkMode }) => normalizeColor("background", Theme, darkMode)};
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, ${({ darkMode }) => normalizeColor("background-front", Theme, darkMode)}),
      color-stop(0.86, ${({ darkMode }) => normalizeColor("background-back", Theme, darkMode)})
    );
  }
`;

export default ScrollContainer;
