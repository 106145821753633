import * as React from "react";
import { singletonHook } from "react-singleton-hook";

const initAllowSearch = false;
let globalSetMode = (mode) => {};

export const useAllowSearch = singletonHook(initAllowSearch, () => {
    const [mode, setMode] = React.useState(initAllowSearch);
    globalSetMode = setMode;
    return mode;
});

export const setAllowSearch = (mode) => globalSetMode(mode);
