import * as React from "react";

import { Anchor, Text } from "grommet";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LinkAnchor = ({ path, label }) => {
  const navigate = useNavigate();

  return (
    <Anchor
      onClick={(e) => {
        navigate(path);
        e.preventDefault();
      }}
      href={`${path}`}
      label={<Text>{label}</Text>}
    ></Anchor>
  );
};

export default LinkAnchor;
