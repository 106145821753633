import * as React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonWrapper = ({ loading, children, ...props }) => {
  if (loading) {
    return (
      <>
        <Skeleton count={props.count || 1}></Skeleton>
        <div style={{ opacity: 0 }}>{children}</div>
      </>
    );
  } else {
    return children;
  }
};

export default SkeletonWrapper;
