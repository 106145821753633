import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Box, Heading, Text } from "grommet";
import React from "react";

function Installer() {
    return (
        <Box align="center" pad="medium" justify="center" gap="small">
            <Text>Installing Scadsys</Text>
            <Box direction="row" gap="small" align="center">
                <Text>Follow us on twitter</Text>
                <FontAwesomeIcon color="#1d9bf0" size="2x" icon={["fab", "twitter"]}></FontAwesomeIcon>
                <Text> while you wait</Text>
            </Box>
        </Box>
    );
}

export default Installer;
